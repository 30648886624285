@import "../../assets/scss/modules/vars.scss";

.loader-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  div {
    text-align: center;
    h5 {
      color: $primary-color;
      font-weight: 400;
    }
  }
}