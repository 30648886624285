@import "./vars.scss";

$positions: top right bottom left;

::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.big-font > * {
  font-size: 25px;
  font-weight: 200;
}

.center {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;

  &__row {
    @extend .center;
    flex-direction: column;
  }
}

.end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  height: 100%;

  &__row {
    @extend .end;
    flex-direction: column;
  }
}

.no-margin {
  margin: 0!important;
}

.min-margin {
  margin: 10px 0;
}

.pic-thumbnail {
  width: 24px;
  height: 24px;
  margin-right: 5px;

  &__medium {
    width: 64px;
    height: 64px;
  }

  &__large {
    width: 128px;
    height: 128px;
  }
}

.circle {
  border-radius: 50%;
}

.inline {
  display: inline-block;
}

.flex { display: flex; flex: 1 1; }

.closable {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  font-size: 20px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.padded {
  padding: 20px;
  position: relative;
  flex: 1;
}

.padded-v {
  padding: 30px 0;
  position: relative;
  flex: 1;
}

.fullW {
  width: 100%;
}

.fullH {
  height: 100vh;
}

.object-fitted {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zoom-scaled {
  filter: blur(3px) grayscale(0.2);
  transform: scale(1.3);
}

.avatar {
  object-fit: cover;
  position: relative;

  &-tiny {
    @extend .avatar;
    width: 48px;
    height: 48px;
    margin: 10px auto;
    border-radius: 50%;
  }

  &-maxwidth {
    @extend .avatar;
    width: 96px;
    height: 96px;
    margin: 10px auto;
    border-radius: 50%;
  }

  &-square {
    @extend .avatar;
    width: 64px;
    height: 64px;
    border-radius: 4px;
  }
}

.title {
  background: $primary-color;
  color: white;
  padding: 5px 0;
}

.h1 {
  font-size: 24px;
}

.h2 {
  font-size: 20px;
}

.h3 {
  font-size: 18px;
}

.h4 {
  font-size: 16px;
}

.h5 {
  font-size: 14px;
}

.margin-auto {
  margin: auto;
}

.dropzone-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 380px;
  border: 2px dashed $primary-color;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  color: $primary-color;
  cursor: pointer;
}

.dropzone-area-button {
  margin: 10px 0;
  text-align: center;
}

.shadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);;
  background: white;
}

.shadow-radius {
  box-shadow: #a9a9a9 0 2px 11px -3px;
  background: white;
  border-radius: 5px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 60 through 200 {
  .image-#{$i} {
    width: 1px + $i;
    height: 1px + $i;
    object-fit: cover;
    border-radius: 5px;
  }
}

@for $i from 1 through 100 {
  .width-#{$i} {
    width: $i * 1%;
  }
}

@for $i from 1 through 100 {
  .min-height-#{$i} {
    min-height: $i * 1vh;
  }
}

@for $i from 0 through 100 {
  .padding-#{$i} {
    padding: $i * 1px;
    box-sizing: border-box;
  }
}

@for $i from 0 through 100 {
  .margin-#{$i} {
    margin: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .paddingV-#{$i} {
    padding-top: $i * 1px;
    padding-bottom: $i * 1px;
    box-sizing: border-box;
  }
}

@for $i from 0 through 100 {
  .paddingH-#{$i} {
    padding-left: $i * 1px;
    padding-right: $i * 1px;
    box-sizing: border-box;
  }
}

@for $i from 0 through 100 {
  .marginH-#{$i} {
    margin-left: $i * 1px;
    margin-right: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .marginV-#{$i} {
    margin-top: $i * 1px;
    margin-bottom: $i * 1px;
  }
}

@each $position in $positions {
  @for $i from 0 through 100 {
    .m-#{$position}-#{$i} {
      margin-#{$position}: $i * 1px;
    }
  }
}

@each $position in $positions {
  @for $i from 10 through 100 {
    .p-#{$position}-#{$i} {
      padding-#{$position}: $i * 1px;
      box-sizing: border-box;
    }
  }
}

@for $i from 0 through 100 {
  .radius-#{$i} {
    border-radius: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .z-index-#{$i} {
    z-index: $i;
  }
}

.pointer {
  cursor: pointer;
}

.invert-link {
  color: $primary-color;
  margin: 0 10px;

  &:hover,
  &:active {
    color: lighten($primary-color, 10);
  }
}

.ant-form-item-label {
  white-space: unset;
}

.ant-col-md-24 {
  width: 100%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.c-label {
  background: $primary-antd;
  padding: 20px;
  border-radius: 5px;
  margin-right: 15px;

  i {
    font-size: 40px;
    color: white;
  }
}

.lh-1 {
  line-height: 1.2;
}

.ant-carousel .slick-slide {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  //max-height: calc(100vh - 101px);
  min-height: calc(100vh - 101px);

  @media screen and (max-width: 480px) {
    //max-height: calc(100vh - 73px);
    min-height: calc(100vh - 73px);
  }
}

.ant-progress-success-bg, .ant-progress-bg {
  height: 10px!important;
}

.ant-slider-vertical {
  display: flex;
  justify-content: center;
}

.ant-slider-vertical .ant-slider-handle {
  //margin-left: -16px;
  margin-left: 0;
}

.ant-input {
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  //border: 1px solid transparent;
  //outline: none;
}

.ant-drawer-body {
  padding: 5px;
}

.img-as-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}

.mobile {
  display: none;
  visibility: hidden;

  @media screen and (max-width: 480px) {
    display: block;
    visibility: visible;
  }
}

.desktop {
  display: block;
  visibility: visible;

  @media screen and (max-width: 480px) {
    display: none;
    visibility: hidden;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.zoom {
  zoom: 1.2;
}

.arrow:not(.anticon) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  background: $primary-color;
  cursor: pointer;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid transparent;

  &-left {
    left: 10px;

    i {
      font-size: 20px;
      color: white;
    }
  }

  &-right {
    right: 10px;

    i {
      font-size: 20px;
      color: white;
    }
  }

  @media screen and (max-width: 480px) {
    transform: translateY(-70%);

    &-left {
      left: 30px;

      i {
        font-size: 15px;
        color: white;
      }
    }

    &-right {
      right: 30px;

      i {
        font-size: 15px;
        color: white;
      }
    }
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.big-button {
  height: 65px;

  * {
    font-size: 25px;
  }
}

.head {
   padding: 10px;
   background: white;
   box-sizing: border-box;
   margin: 0 0 10px 0;
   border-radius: 5px;

   h5 {
     margin: 0;
   }

   &__bordered {
     border: 1px solid #e4e4e4;

     &--filled {
       border: 2px solid #FF2E63;
       border-radius: 5px;
     }
   }
 }
