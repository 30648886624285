//----------------------------------*\
// Import external vendors
//----------------------------------*/
@import url('https://cdnjs.cloudflare.com/ajax/libs/sweetalert/1.1.3/sweetalert.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
@import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');
//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/
//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/

// Config modules
@import 'modules/vars.scss';

//----------------------------------*\
// PARTIALS
//----------------------------------*/

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/

@import 'modules/misc.scss';


// Enhancement modules

//----------------------------------*\
// VENDOR
//----------------------------------*/

@import 'vendor/external.scss';