//----------------------------------*\
// Links
//----------------------------------*/
$color: #4a4a4a;

//----------------------------------*\
// Colors
//----------------------------------*/

$default-color: #808080;
$moon: #fbfbfb;
$base : #f5f5f5;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);
$orange: #ff6428;
$warning: #ff8749;
$danger: #d94d45;
$sucessful: #40c288;
$info: #2b73c2;
$even-color: #fbfbfb;
$green: #20d280;
$check-green: #2cc60f;
$grayButton: #f0f0f0;

$palette-purple: #262D5E;
$palette-orange: #FF653A;
$palette-pink: #FF2E63;
$primary-antd: #FF2E63;
$palette-gradient: linear-gradient(135deg, $palette-orange 10%, $palette-pink 100%);
$palette-gradient-inverse: linear-gradient(135deg, $palette-pink 10%, $palette-orange 100%);

$primary-color: #FF2E63;
$secundary-color: $palette-purple;
$bdb-alliance-color: #0040A8;

//----------------------------------*\
// Social networks
//----------------------------------*/
$facebook: #425f9c;
$google: #e16554;
$twitter: #1da1f2;
